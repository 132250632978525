import { render, staticRenderFns } from "./AppealChat.vue?vue&type=template&id=5095c47a&scoped=true&"
import script from "./AppealChat.vue?vue&type=script&lang=ts&"
export * from "./AppealChat.vue?vue&type=script&lang=ts&"
import style0 from "./AppealChat.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AppealChat.vue?vue&type=style&index=1&id=5095c47a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5095c47a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFileInput,VIcon,VTextarea})
