














































































































import {Component, Emit, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import { mapGetters, mapState, mapMutations } from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import XForm from "@/components/SimpleForm.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import {validationMixin} from "vuelidate";
import { appealChatForm } from "@/assets/scripts/form-fields/main";
import {AppealsByContract, AppealThemes, AppealThemeTypes,AppealChatMessages,AppealChatFiles,AppealHistoryFileInfo } from "@/models/appeals";
import {AppealHistoryRefreshReq} from "@/models/app-api";
import {OrganizationAgent} from "@/models/organization";

import Appeals from "@/views/Appeals.vue";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";

const { values, attrs, validations, errors } = appealChatForm;

@Component({
  components: {
    Page,
    XForm,
    XNotice
  },
  computed: {
    ...errors,
    ...mapGetters({
      contractId: "contract/id",
      appealChat_get: "appeals/appealHistory",
      currentAppealInfo: "appeals/getCurrentAppealInfo",
      orgContactAgent: "organization/agentList",
      unreadAppealsCount: "appeals/unreadAppealsCount"
    }),
  },
  methods: {
    ...mapMutations("appeals", {
      setUnreadCounterValue: "setUnreadCounterValue",
    }),
    ...mapMutations("sidebar", {
      setCounterValue: "setCounterValue",
    }),
  },
  mixins: [validationMixin],
  validations,
})

class AppealChat extends Mixins(AppApiMixin, XForm, DocGetterMixin) {
  [x: string]: any;

  @Prop({ default: false }) readonly show!: boolean;

  successMessage = 'Обращение успешно отправлено.'

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  useSuccessAlert = false;
  submitError = '';
  submitSuccess = '';
  //file: File | string = "";
  file: Array<File> | [];
  file_count = 0;
  fileInfo: any;

  appealsChatList: AppealChatMessages[] = [];
  appealsChatListFiles: AppealChatFiles[] = [];
  appealsChatProps: AppealHistoryRefreshReq = {
    contractId: this.contractId,
    appealId: -1
  }
  allFiles: AppealChatFiles[];

  newarr: [];
  sendAuthorStr = '';

  created() {
    this.values = {...this.values};
    this.defaultValues = {...this.values};

    // сущности файлов
    this.getAppealFiles({
      contractId: this.contractId,
      appealId: parseInt(this.currentAppealInfo[0]['appealId'])
    }, (appealsChatListFiles: AppealChatFiles[]) => {
      this.allFiles = appealsChatListFiles;
      console.log(this.allFiles);
    });

    // история сообщений
    this.getAppealHistory({
      contractId: this.contractId,
      appealId: parseInt(this.currentAppealInfo[0]['appealId'])
    },(appealsChatList: AppealChatMessages[]) => {
      //console.log(appealsChatList);
      //var count = 0;
      console.log(appealsChatList);


      Array.from(appealsChatList).forEach((item,index) => {
        Array.from(this.allFiles).forEach((item2,index2) => {
          if (appealsChatList[index]['содержание'].search((this.allFiles[index2]['имя файла'])) != -1) {
            appealsChatList[index]['номер_ид_файла'] = this.allFiles[index2]['$номерЗаписи'];
            console.log(appealsChatList[index]['содержание']);
            console.log(this.allFiles[index2]['имя файла']);
          }
        });

        /*        if (appealsChatList[index]['содержание'].match(/^(Прикреплен\sфайл)/)) {
                  //this.newarr.push(appealsChatList[index]);
                  //appealsChatList[index]['номер_ид_файла'] = this.allFiles[index]['$номерЗаписи'];
                } else {

                }*/

      });

      /*      this.newarr.forEach((item,index) => {
              this.newarr[index]['номер_ид_файла'] = this.allFiles[index]['$номерЗаписи'];
            });*/
      console.log(appealsChatList);

      this.appealsChatList = appealsChatList;
    });

    // получение строки заявителя
    if (Array.from(this.orgContactAgent).length > 0) {
      Array.from(this.orgContactAgent).map((item: OrganizationAgent) => {
        this.sendAuthorStr += item.фио + ': ' + item.должность + ' ';
      });
    } else {
      this.sendAuthorStr = this.nameOrg;
    }

    let end_check = Array.from(this.unreadAppealsCount).find(item => item['$номерЗаписи'] == this.currentAppealInfo[0]['appealId']);

    if (end_check) {
      this.resetUnreadAppeal(
        this.currentAppealInfo[0]['appealId'],
        (data) => {
          if (data && data.data.result) {
            let fin_mas = Array.from(this.unreadAppealsCount).filter(item => item['$номерЗаписи'] != this.currentAppealInfo[0]['appealId']);
            if (fin_mas.length !=0) {
              this.setUnreadCounterValue(fin_mas);
              this.setCounterValue(fin_mas.length);
            } else {
              this.setUnreadCounterValue([]);
              this.setCounterValue(0);
            }
            this.$emit('updateCounter');

          }
        }
      );
    }
  }

  @Emit('updateChatTable')
  public appealsChatUpdate() {
    this.getAppealHistory(
      {contractId: this.contractId,appealId: this.currentAppealInfo[0]['appealId']},
      (appealsChatList: AppealChatMessages[]) => {
        this.appealsChatList = appealsChatList;
      }
    );
  }

  /*  onFileChanged(files: File) {
      this.file = files
    }*/
  onFileChanged(files: Array<File>) {
    this.file = files;
  }

  downloadAppealHistoryFile(number_id, text_info) {
    console.log(number_id,text_info);
    if (text_info.match(/^(Прикреплен\sфайл)/)) {
      this.getHistoryAppealFileInfo({
        contractId: this.contractId,
        appealId: this.currentAppealInfo[0]['appealId'],
        fileId: number_id
      },(appealFileClickInfo: AppealHistoryFileInfo[]) => {
        console.log(appealFileClickInfo);
        this.fileInfo = appealFileClickInfo;
        this.downloadTheFile();
      });
    }
  }

  public downloadTheFile(): void {
    const {
      appealHistoryFileDownload,
      downloadDocument
    } = this;

    appealHistoryFileDownload({
      fileUrl: this.fileInfo['fileUrl'],
      fileId: this.fileInfo['fileId'],
      download: true,
    })
      .then((response) => downloadDocument(response))
      .catch(console.error);
  }



  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    const formData = new FormData

    for (let key in this.values) {
      formData.append(key, this.values[key]);
    }

    for (let fille of this.file) {
      //let i = 0;
      formData.append('file2_'+this.file_count,fille);
      this.file_count++;
    }

    formData.append('contractId', this.contractId);
    formData.append('author', this.sendAuthorStr);
    formData.append('appealId', this.currentAppealInfo[0]['appealId']);
    formData.append('file_count', String(this.file_count));



    //formData.append('file2', this.file);

    try {
      const data = await this.sendAppealMessage(formData);
      if(data && data.result) {
        this.submitSuccess = 'Сообщение успешно отправлено';
        this.values = {...this.defaultValues}
        this.useSuccessAlert = true
        //@ts-ignore
        //this.$refs.fileInput.value = ''
        //@ts-ignore
        //this.$refs.fileInput.reset()

        /*        Array.from(this.$refs.file2.$refs.input.files).splice(0,Array.from(this.$refs.file2.$refs.input.files).length-1);
                console.log(this['$refs']['file2']['$refs']['input']);*/

        //let asd = this['$refs']['file2']['$refs']['input'];
        /*        console.log(asd);
                console.log(asd.files);*/

        for (let key in this['$refs']['file2']['$refs']['input']['files'][0]) {
          //console.log(asd.files[0][key]);
        }
        document.querySelector('.v-file-input__text').innerHTML = '';
        document.querySelector('.v-file-input .v-input__slot .mdi.mdi-close').dispatchEvent(new Event('click'));

        this.appealsChatUpdate();
      }
    } catch (e) {
      console.log('error', e)
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false

  }

}

export default AppealChat;
